import { Network } from '@capacitor/network';

class HealthCheckService {
  async isNetworkConnected(): Promise<boolean> {
    const res = await Network.getStatus();
    return res.connected;
  }
}

export const healthCheckService = new HealthCheckService();
export default healthCheckService;
