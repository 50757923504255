import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import Spinner from 'common/components/base/spinner/spinner';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import loginService from 'features/eiq-connect-login/service/login.service';
import { resetPassword } from 'features/eiq-connect-login/validation/login.validation';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { isEmail } from 'common/helpers/email.helper';
import ResetPassword from './components/reset-password';
import { notification } from 'common/utils/notification';
import styles from './forgot-password.module.scss';
import { getLogin } from 'common/helpers/login.helper';
import healthCheckService from 'common/services/helthcheck-service/helscheck-service';

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [isSuccessSent, setSuccessSent] = useState(false);

  const onFinish = async (values: { login: string }) => {
    const login = getLogin(values.login);

    // TODO: Add a server availability check with different error, after EIQ-1080
    const isConnected = await healthCheckService.isNetworkConnected();

    if (!isConnected) {
      notification.error({ message: `No internet connection. Please check your network and try again.` });
      return;
    }

    try {
      await loginService.resetPassword(login);
      setSuccessSent(true);
    } catch (error: any) {
      notification.error({ message: `User with ${isEmail(login) ? 'email' : 'phone'} '${login}' not found` });
      throw error;
    }
  };

  return (
    <>
      {isSuccessSent && <ResetPassword login={getLogin(form.getFieldValue('login'))} />}
      {!isSuccessSent && (
        <>
          <FormWrapper
            className="eiq-connect-auth-form-wrapper"
            form={form}
            onFinish={onFinish}
            name="validateOnly"
            layout="vertical"
            autoComplete="on"
            initialValues={{ remember: true }}>
            <Row>
              <Col>
                <div className="title">Forgot the password?</div>
                <div className="text">Enter your email below to reset your password.</div>
              </Col>
            </Row>
            <Form.Item name="login" label="Email" rules={resetPassword.login}>
              <Input name="login" placeholder="Enter email" autoCapitalize="none" autoCorrect="off" />
            </Form.Item>
            <div className="action-line">
              <Button type="primary" htmlType="submit" icon={<RightOutlined />} className={styles.button}>
                Reset password
              </Button>
            </div>
            <div className="text text-center ant-mt-16">
              <Link to="/login">Back to log in</Link>
            </div>
          </FormWrapper>
          <Spinner />
        </>
      )}
    </>
  );
};

export default ForgotPassword;
