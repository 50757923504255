import './eiq-connect-add-edit.scss';
import { Button, DatePicker, Divider, Form, Input, Select, SelectProps, Space } from 'antd';
import React, { useEffect, useState, useRef } from 'react';

import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import './eiq-connect-add-edit.scss';
import { FormWrapper } from '../../../../../common/components/form-wrapper/form-wrapper';
import { GuestValidity } from 'common/enums/guest-validity.enum';
import { daysOfWeek } from 'common/constans/common.constants';
import { DATE_SHORT_MMM_DD_YYYY, DATE_YYYY_MM_DD } from 'common/constans/date.constants';
import { GuestDateCheck, ICreateGuest, IUpdateGuest } from 'common/services/guest-service/guest.service.types';
import { getVisitDateByGuestValidity, mapToServerGuestValidity } from 'common/helpers/guest-validity.helper';
import { GuestType } from 'common/enums/guest-type.enum';
import { addEditGuestValidation } from 'common/validation/guest.validation';
import { validityOptions } from 'common/constans/guest-validity-options.constant';
import { guestTypeOptions } from 'common/constans/guest-type-options.constant';
import { daysOfWeekCheckboxOptions } from 'common/constans/days-of-week.constants';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { StrToDate, StrToDateTimeLocal } from 'common/helpers/date.helper';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import guestService from 'common/services/guest-service/guest.service';
import { notification } from 'common/utils/notification';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { IFormInitData } from './eiq-connect-add-edit.types';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import ConfirmationModal from 'features/eiq-connect/components/confirmation-modal/confirmation-modal';
import { ReactComponent as Trash } from 'assets/icons/eiq-connect/trash-white.svg';
import residentsService from 'common/services/residents-service/residents.service';
import { IGuestRead } from 'common/services/residents-service/residents.service.types';
import { AddEditPageHeader } from 'features/eiq-connect/components/add-edit-page-header/add-edit-page-header';
import { AddEditActions } from 'features/eiq-connect/components/add-edit-actions/add-edit-actions';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { CheckboxGroup } from '../../../../../common/components/form-elements/checkbox-group/checkbox-group';
import SelectState from 'common/components/form-elements/select-state/select-state';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { ICreateGuestFastAccessPass } from 'common/services/fast-access-service/fast-access-service.types';
import { UseModalHookType } from 'common/hooks/use-modal/use-modal.type';
import featureStore from 'common/stores/features/feature.store';
import { FeatureName } from 'common/enums/feature-name.enum';
import { IServerErrorMessage } from 'common/utils/http/http-client.types';
import configurationStore from 'common/stores/configuration/configuration.store';

const initData: IFormInitData = {
  name: null,
  type: null,
  validity: null,
  daysOfWeek: null,
  startDate: null,
  endDate: null,
  visitDate: null,
  companyName: null,
  addressId: null,
};
interface EiqConnectAddEditProps {
  shareFastAccessModal: UseModalHookType;
  setGuestFastAccessPassModel: React.Dispatch<React.SetStateAction<ICreateGuestFastAccessPass | undefined>>;
}

export const EiqConnectAddEdit: React.FC<EiqConnectAddEditProps> = observer(({ shareFastAccessModal, setGuestFastAccessPassModel }) => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const isAddPath = location.pathname.includes('/add');
  const [form] = Form.useForm();
  const deleteGuestModal = useModal({});

  const [currentGuest, setCurrentGuest] = useState<IGuestRead | null>(null);

  const [isShowDateRange, setIsShowDateRange] = useState<boolean>(false);
  const [isShowDaysOfVisits, setIsShowDaysOfVisits] = useState<boolean>(false);
  const [isShowCompanyName, setIsShowCompanyName] = useState<boolean>(false);
  const [isShowVisitDate, setIsShowVisitDate] = useState<boolean>(false);
  const [addressOptions, setAddressOptions] = useState<SelectProps['options']>([]);
  const [guestOptions, setGuestOptions] = useState<SelectProps['options']>(guestTypeOptions);
  const isFormSubmittedSuccessful = useRef(false);

  useEffect(() => {
    if (!eiqConnectStore.residentDetails) {
      return;
    }

    const addressOptions = eiqConnectStore.residentDetails?.addresses?.map((item) => ({
      value: item.address.id,
      label: formatAddressLabel(item.address),
    }));

    setAddressOptions(addressOptions || []);

    if (addressOptions && addressOptions.length === 1) {
      addressChangeHandle(addressOptions[0].value!);
    }

    if (!isAddPath) {
      const guestId = location.pathname.match(/\d+/)![0];

      const guest = eiqConnectStore.residentDetails.addresses.flatMap((item) => item.guests).find((item) => item.id === Number(guestId));
      setCurrentGuest(guest!);
    }
  }, [eiqConnectStore.residentDetails]);

  useEffect(() => {
    if (!form || !currentGuest) {
      return;
    }

    const addressId = eiqConnectStore.residentDetails?.addresses?.find((item) => item.guests.includes(currentGuest))?.address.id;

    const initialValues = formatValuesForForm(currentGuest, addressId!);

    form.setFieldsValue(initialValues);

    if (initialValues?.validity) {
      validityChangeHandle(initialValues.validity);
    }
    if (initialValues?.type) {
      typeChangeHandle(initialValues.type);
    }

    if (initialValues?.addressId) {
      addressChangeHandle(initialValues.addressId);
    }
  }, [form, currentGuest]);

  // Voice Form Event
  useEffect(() => {
    const voiceForm = eiqConnectStore.guestVoiceFrom;

    function splitName(fullName?: string) {
      const trimmedName = fullName?.trim();
      const [_, firstName, lastName] = /^(\S+)(?:\s+(.+))?$/.exec(trimmedName ?? '') || [];

      return {
        firstName: firstName || '',
        lastName: lastName || '',
      };
    }

    if (voiceForm) {
      form.setFieldsValue({
        ...splitName(voiceForm?.name),
        type: voiceForm?.type,
        companyName: voiceForm?.companyName,
        validity: voiceForm?.validity,
        carNumber: voiceForm?.carNumber,
        carState: voiceForm?.carState,
      });

      typeChangeHandle(voiceForm?.type as GuestType);

      if (voiceForm?.validity) {
        validityChangeHandle(voiceForm.validity);
      }

      eiqConnectStore.setGuestVoiceFrom(null);
    }

    return () => {
      if (!isFormSubmittedSuccessful.current) {
        notification.destroy();
      }
    };
  }, []);

  const formatValuesForForm = (currentGuest: IGuestRead, addressId: number) => {
    return {
      id: currentGuest?.id,
      firstName: currentGuest?.firstName,
      lastName: currentGuest?.lastName,
      type: currentGuest?.type,
      validity: currentGuest?.validity,
      isKeyPermissionAllowed: currentGuest?.isKeyPermissionAllowed,
      daysOfWeek: currentGuest?.daysOfWeek,
      startDate: StrToDate(currentGuest?.startDate),
      endDate: StrToDate(currentGuest?.endDate),
      visitDate: StrToDate(currentGuest?.visitDate),
      restrictions: currentGuest?.restrictions,
      companyName: currentGuest?.companyName,
      addressId: addressId,
      carNumber: currentGuest?.carLicensePlates.find((i) => i.isPrimary)?.number,
      carState: currentGuest?.carLicensePlates.find((i) => i.isPrimary)?.state,
    };
  };

  const updateResidentDetails = () => {
    const residentId: number | null = userAuthStore.webGuestResidentId!;
    residentsService
      .getResidentDetails(residentId)
      .then((data) => {
        eiqConnectStore.setResidentDetails(data);
        navigate('/eiq-connect/guests');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getAddressId = (values: IFormInitData | null) => {
    if (!isAddPath) {
      return eiqConnectStore.residentDetails?.addresses?.find((item) => item.guests.includes(currentGuest!))?.address.id!;
    }

    if (addressOptions && addressOptions.length === 1) {
      return addressOptions[0].value!;
    }

    return values?.addressId!;
  };

  const onFinish = async (values: any) => {
    let days = [];
    if (values.validity === GuestValidity.Permanent) {
      days = values.daysOfWeek.length === daysOfWeek.length ? daysOfWeek : values.daysOfWeek;
    }

    const getDatePeriod = (startDate: any, endDate: any) => {
      return {
        startDate: isShowDateRange ? startDate.format(DATE_YYYY_MM_DD) : null,
        endDate: isShowDateRange ? endDate.format(DATE_YYYY_MM_DD) : null,
      };
    };

    const guest: ICreateGuest = {
      firstName: values.firstName,
      lastName: values.lastName,
      type: values.type,
      validity: mapToServerGuestValidity(values.validity),
      isKeyPermissionAllowed: currentGuest?.isKeyPermissionAllowed || false,
      daysOfWeek: isShowDaysOfVisits ? days : [],
      period: getDatePeriod(values.startDate, values.endDate),
      restrictions: currentGuest?.restrictions || null,
      companyName: isShowCompanyName ? values.companyName : '',
      visitDate: getVisitDateByGuestValidity(values.validity, values.visitDate),
      carNumber: values.carNumber,
      carState: values.carState,
    };

    const residentId: number | null = userAuthStore.webGuestResidentId!;

    if (!isAddPath) {
      const guestToUpdate = guest as IUpdateGuest;
      guestToUpdate.id = currentGuest!.id;

      await guestService.handleGuestsLimitationsErrors(() => guestService.updateGuest(residentId, +getAddressId(values), guestToUpdate));
      isFormSubmittedSuccessful.current = true;
      updateResidentDetails();
      notification.success({ message: 'Guest information was successfully updated' });
    } else {
      const createdGuest = await guestService.handleGuestsLimitationsErrors(() =>
        guestService.createGuest(residentId, +getAddressId(values), guest),
      );

      isFormSubmittedSuccessful.current = true;

      const model: ICreateGuestFastAccessPass = {
        guestId: createdGuest.id,
        residentId: residentId,
        addressId: +getAddressId(values),
      };

      setGuestFastAccessPassModel(model);

      updateResidentDetails();
      notification.success({ message: 'Guest was successfully created' });

      const expairedCheckData: GuestDateCheck = {
        validity: createdGuest.validity,
        visitDate: createdGuest.visitDate!,
        endDate: createdGuest.endDate!,
      };
      const isGuestExpired = guestService.isExpired(expairedCheckData);

      if (featureStore.IsEnabled(FeatureName.FastAccess) && guest && !isGuestExpired) {
        shareFastAccessModal.showModal();
      }
    }
  };

  const validityChangeHandle = (validity: string) => {
    switch (validity) {
      case GuestValidity.DateRange:
        setIsShowDateRange(true);
        setIsShowDaysOfVisits(false);
        setIsShowVisitDate(false);
        break;

      case GuestValidity.Permanent:
        setIsShowDateRange(false);
        setIsShowDaysOfVisits(true);
        setIsShowVisitDate(false);
        const guestDaysOfWeek = currentGuest?.daysOfWeek;
        if (!guestDaysOfWeek || guestDaysOfWeek.length === 0) {
          form.setFieldsValue({ daysOfWeek: daysOfWeekCheckboxOptions.map((option) => option.value) });
        }
        break;

      case GuestValidity.OneTime:
        setIsShowDateRange(false);
        setIsShowDaysOfVisits(false);
        setIsShowVisitDate(true);
        break;

      default:
        setIsShowDateRange(false);
        setIsShowDaysOfVisits(false);
        setIsShowVisitDate(false);
        break;
    }
  };

  const typeChangeHandle = (guestType: GuestType) => {
    isServiceGuest(guestType) ? setIsShowCompanyName(true) : setIsShowCompanyName(false);

    if (guestType === GuestType.UrgentService && !form.getFieldValue('validity')) {
      form.setFieldValue('validity', GuestValidity.Today);
    }
  };

  const addressChangeHandle = (addressId: number) => {
    const address = eiqConnectStore.residentDetails?.addresses.find((item) => item.address.id === addressId);

    if (address?.ownershipType === ResidentTypesEnum.Developer) {
      setGuestOptions(guestTypeOptions.filter((item) => isServiceGuest(item.value as GuestType) && item.value !== GuestType.UrgentService));

      const currentFormValue = form.getFieldValue('type');

      if (currentFormValue && (!isServiceGuest(currentFormValue) || currentFormValue === GuestType.UrgentService)) {
        form.resetFields(['type']);
      }
    } else {
      setGuestOptions(guestTypeOptions);
    }
  };

  const onDeleteGuest = () => {
    guestService
      .deleteGuest(userAuthStore.webGuestResidentId!, +getAddressId(null), currentGuest!.id)
      .then((_res) => {
        updateResidentDetails();
        notification.success({ message: 'Guest was successfully deleted' });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <FormWrapper
      form={form}
      onFinish={onFinish}
      initialValues={initData}
      layout="vertical"
      className="eiq-connect-add-edit eiq-add-edit-page">
      <AddEditPageHeader isMobileOrTablet={isMobileOrTable} pageType={isAddPath ? 'ADD' : 'EDIT'} />
      <div className="eiq-content">
        {isAddPath && addressOptions && addressOptions.length > 1 && (
          <Space className="full-width">
            <div className="eiq-connect-block">
              <Form.Item name="addressId" label="Address" rules={addEditGuestValidation.addressId}>
                <Select options={addressOptions} placeholder="Select address" onChange={addressChangeHandle} />
              </Form.Item>
              <Divider className="account-diver" />
            </div>
          </Space>
        )}
        <Space className="full-width">
          <div className="eiq-connect-block">
            <Form.Item name="type" label="Visitor Type" rules={addEditGuestValidation.type}>
              <Select options={guestOptions} onChange={typeChangeHandle} placeholder="Select a type" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          {isShowCompanyName && (
            <div className="eiq-connect-company-block">
              <Form.Item name="companyName" className="eiq-input" label="Company name" rules={addEditGuestValidation.companyName}>
                <Input name="companyName" placeholder="Enter company name" />
              </Form.Item>
              <Divider className="account-diver" />
            </div>
          )}
        </Space>
        <Space className="full-width">
          <div className="eiq-connect-block">
            <Form.Item name="validity" label="Access Type (Validity)" rules={addEditGuestValidation.validity}>
              <Select options={validityOptions} onChange={validityChangeHandle} placeholder="Select a validity" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          {isShowDateRange && (
            <>
              <Space className="full-width eiq-date-picker">
                <div className="eiq-date-range-block">
                  <Form.Item className="full-width" name="startDate" label="From" rules={addEditGuestValidation.startDate}>
                    <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    name="endDate"
                    label="To"
                    rules={addEditGuestValidation.endDate}
                    dependencies={['startDate']}>
                    <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
                  </Form.Item>
                </div>
                <Divider className="account-diver" />
              </Space>
            </>
          )}
          {(isShowVisitDate || isShowDaysOfVisits) && (
            <>
              <Space className="full-width eiq-date-picker">
                {isShowVisitDate && (
                  <>
                    <Form.Item name="visitDate" label="Visit date" rules={addEditGuestValidation.visitDate}>
                      <DatePicker placeholder="Visit date" format={DATE_SHORT_MMM_DD_YYYY} />
                    </Form.Item>
                    <Divider className="account-diver" />
                  </>
                )}
                {isShowDaysOfVisits && (
                  <>
                    <Form.Item name="daysOfWeek" label="Days allowed to visit" rules={addEditGuestValidation.daysOfWeek}>
                      <CheckboxGroup options={[...daysOfWeekCheckboxOptions]} />
                    </Form.Item>
                    <Divider className="account-diver" />
                  </>
                )}
              </Space>
            </>
          )}
        </Space>
        <Space className="full-width">
          <div className="eiq-connect-block">
            <Form.Item
              name="firstName"
              label={`First name${isShowCompanyName ? ' (Optional)' : ''}`}
              rules={!isShowCompanyName ? addEditGuestValidation.firstName : undefined}>
              <Input name="firstName" placeholder="Enter first name" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-connect-block">
            <Form.Item
              name="lastName"
              label={`Last name${isShowCompanyName ? ' (Optional)' : ''}`}
              rules={!isShowCompanyName ? addEditGuestValidation.lastName : undefined}>
              <Input name="lastName" placeholder="Enter last name" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-connect-block">
            <Form.Item name="carNumber" label="License plate (Optional)">
              <Input name="carNumber" placeholder="Enter license plate" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-connect-block">
            <Form.Item name="carState" label="License state (Optional)">
              <SelectState placeholder="Select a license state" allowClear={true} />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        {!isAddPath && (
          <Button type="link" onClick={deleteGuestModal.showModal}>
            Delete guest
          </Button>
        )}
      </div>
      {isMobileOrTable && (
        <div className="eiq-footer-actions">
          <AddEditActions type={isAddPath ? 'ADD' : 'EDIT'} />
        </div>
      )}
      <ConfirmationModal
        title="Delete"
        isOpen={deleteGuestModal?.isOpenModal}
        onClose={deleteGuestModal?.hideModal}
        mainText={
          <>
            Are you sure you want to delete{' '}
            <span>
              {currentGuest?.firstName} {currentGuest?.lastName}
            </span>{' '}
            from the guest list?
          </>
        }
        confirmBtnText={'Delete'}
        confirmBtnIcon={<Trash />}
        handleConfirm={onDeleteGuest}
      />
    </FormWrapper>
  );
});
