import { makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode'; // Import the JWT decoding library
import React from 'react';
import { ACCESS_TOKEN_KEY, IS_USER_PREVIOUSLY_WAS_LOGGED, KEEP_ME_LOGGED_IN, REFRESH_TOKEN_KEY } from './user-auth.constants';
import { UserTypeEnum } from '../../enums/user-type.enum';
import configurationStore from '../configuration/configuration.store';
import wgLoginService from 'features/eiq-connect-login/service/login.service';
import fcmService from '../../../features/eiq-connect/service/fcm-service/fcm.service';
import { Capacitor } from '@capacitor/core';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';
import featureStore from '../features/feature.store';
import fastAccessPassStore from '../fast-access-store/fast-access-store';
import { getItem, removeItem, setItem } from 'common/helpers/authorization-store.helper';
import analyticsService from '../../../features/eiq-connect/service/analytics-service/analytics.service';

class UserAuthStore {
  isLogin = false;
  userType: UserTypeEnum | null = null;
  roles: string[] = [];
  permissions: string[] = [];
  profile: Record<string, any> | null = null;
  signUpValues: any = null;
  prevPath: string | null = null;
  webGuestResidentId: number | null = null;
  keepMeLoggedIn: boolean = true;

  constructor() {
    makeAutoObservable(this);
    this.restoreUserStore();
  }

  setKeepMeLoggedIn(keepMeLoggedIn: boolean) {
    this.keepMeLoggedIn = keepMeLoggedIn;
  }

  setUserData(accessToken: string, refreshToken: string) {
    setItem(ACCESS_TOKEN_KEY, accessToken);
    setItem(REFRESH_TOKEN_KEY, refreshToken);

    localStorage.setItem(IS_USER_PREVIOUSLY_WAS_LOGGED, JSON.stringify(true));

    this.restoreUserStore();
  }

  setAccessToken(accessToken: string) {
    setItem(ACCESS_TOKEN_KEY, accessToken);
    this.restoreUserStore();
  }

  setSignUpValues(values: any) {
    this.signUpValues = values;
  }

  restoreUserStore() {
    const accessToken = getItem(ACCESS_TOKEN_KEY);
    const isBackedURL = communityResolverService.getCurrentCommunityUrl()?.length;
    if (!accessToken || accessToken === 'undefined' || !isBackedURL) {
      return this.logOut();
    }

    this.resetUserStore();
    const decodedToken: any = jwt_decode(accessToken);
    const { user_type, permission } = decodedToken;
    this.isLogin = true;
    this.userType = permission === 'self' ? UserTypeEnum.WebGuest : user_type;
    this.permissions = permission ?? [];

    if (this.userType !== UserTypeEnum.WebGuest) {
      return this.logOut();
    }

    const key = Object.keys(decodedToken).find((k) => k.includes('nameidentifier'));
    const nameIdentifier = decodedToken[key!];
    this.webGuestResidentId = nameIdentifier;
  }
  // setRoles(roles: string[]) {
  //   this.roles = roles;
  // }
  //
  // setPermissions(permissions: string[]) {
  //   this.permissions = permissions;
  // }
  //
  // setProfile(profile: Record<string, any>) {
  //   this.profile = profile;
  // }

  async requestTokenForResident(login: string, password: string, captchaToken: string, onFailCallback: (error: any) => void) {
    try {
      if (this.keepMeLoggedIn) {
        localStorage.setItem(KEEP_ME_LOGGED_IN, JSON.stringify(this.keepMeLoggedIn));
      } else {
        sessionStorage.setItem(KEEP_ME_LOGGED_IN, JSON.stringify(this.keepMeLoggedIn));
      }

      const response = await wgLoginService.authenticate(login, password, captchaToken!);
      if (!response && onFailCallback) {
        onFailCallback(null);
        return;
      }
      const { accessToken, refreshToken } = response;
      this.setUserData(accessToken, refreshToken);
      await configurationStore.requestConfiguration();
      await featureStore.requestFeatures();

      if (Capacitor.isNativePlatform()) {
        fcmService.initPush();
      }
      analyticsService.initializeAnalytics();
    } catch (error) {
      console.error('Error requesting tokens:', error);
      if (onFailCallback) {
        onFailCallback(error);
      }
    }
  }

  public resetUserStore() {
    this.isLogin = false;
    this.userType = null;
    this.roles = [];
    this.permissions = [];
    this.profile = null;
    this.signUpValues = null;
    this.webGuestResidentId = null;
  }

  async logOut() {
    await fcmService.unsubPush();

    removeItem(ACCESS_TOKEN_KEY);
    removeItem(REFRESH_TOKEN_KEY);

    communityResolverService.reset();
    this.prevPath = null;
    this.resetUserStore();
    configurationStore.clearConfiguration();
    featureStore.clearFeatures();
    fastAccessPassStore.clear();

    localStorage.removeItem(KEEP_ME_LOGGED_IN);
  }

  setPrevPath = (path: string) => {
    this.prevPath = path;
  };
}

const userAuthStore = new UserAuthStore();
export const UserAuthStoreContext = React.createContext<UserAuthStore>(userAuthStore);
export default userAuthStore;
