import { ServerGuestValidity, GuestValidity } from 'common/enums/guest-validity.enum';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { DATE_YYYY_MM_DD } from 'common/constans/date.constants';
import { IGuestRead } from 'common/services/residents-service/residents.service.types';
import { formatCustomDateTime } from './date.helper';
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const mapToGuestValidity = (validity: ServerGuestValidity | null | string, visitDate: Dayjs | null) => {
  switch (validity) {
    case ServerGuestValidity.DateRange:
      return GuestValidity.DateRange;
    case ServerGuestValidity.Permanent:
      return GuestValidity.Permanent;
    case ServerGuestValidity.OneTime: {
      if (visitDate?.isToday()) {
        return GuestValidity.Today;
      }
      if (visitDate?.isTomorrow()) {
        return GuestValidity.Tomorrow;
      }
      return GuestValidity.OneTime;
    }
    default:
      return GuestValidity.Undefined;
  }
};

export const mapToServerGuestValidity = (validity: GuestValidity | null) => {
  switch (validity) {
    case GuestValidity.DateRange:
      return ServerGuestValidity.DateRange;
    case GuestValidity.Permanent:
      return ServerGuestValidity.Permanent;
    case GuestValidity.OneTime:
    case GuestValidity.Today:
    case GuestValidity.Tomorrow:
      return ServerGuestValidity.OneTime;
    default:
      return ServerGuestValidity.Undefined;
  }
};

export const getVisitDateByGuestValidity = (validity: GuestValidity, visitDate: Dayjs | null) => {
  switch (validity) {
    case GuestValidity.OneTime:
      return visitDate?.format(DATE_YYYY_MM_DD);
    case GuestValidity.Today:
      return dayjs().format(DATE_YYYY_MM_DD);
    case GuestValidity.Tomorrow:
      return dayjs().add(1, 'day').format(DATE_YYYY_MM_DD);
    default:
      return null;
  }
};

export const getValidityDate = (rowData: IGuestRead) => {
  if (rowData.validity === GuestValidity.DateRange) {
    return `${formatCustomDateTime(rowData?.startDate)} - ${formatCustomDateTime(rowData?.endDate)}`;
  }
  if (rowData.validity === GuestValidity.OneTime) {
    return formatCustomDateTime(rowData?.visitDate);
  }
  return rowData.validity;
};
